body {
  margin: 0;
  padding: 0;
  font-size: 30px;
}

h1 {
  font-family: 'Montserrat',
    sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 120px;
  line-height: 80%;
  margin: 0;
}

@media only screen and (max-width: 650px) {
  body {
    font-size: 17px;
  }

  h1 {
    font-size: 40px;
  }

}

p {
  margin: 0;
}
.outerSwitch{
  width: 45px;
  background-color: #F2C94C;
  border-radius: 14px;
  padding: 2px;
  display: block;
  position: relative;
  margin-right: 20px;
  bottom: 0px;
}

.innerSwitch{
  width: 28px;
  height: 22px;
  background-color: #F2F2f2;
  border-radius: 14px;
  position: relative;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transition-duration:.1s;
          transition-duration:.1s;
  -webkit-transition-property: left;
  transition-property: left;
  left: 0px;
  
}

.on{
  left: 17px;
}

.darkModeText {
  display: inline-block;
  font-size: 20px;
  position: relative;
}
.DarkMode{
  margin-top: 30px;
}
.Card {
  max-width: 600px;
  padding: 40px;
  border-radius: 20px;
  margin: auto;
}

.Line {
  background-color: #F2C94C;
  height: 10px;
  margin-top: 27px;
  margin-bottom: 27px;
  border-radius: 10px;
}

.Email {
  margin-top: 100px;

}
@media only screen and (max-width: 650px) {
  .Email {
    font-size: 15pt;
  }
}

u {
  -webkit-text-decoration-color: #F2C94C;
          text-decoration-color: #F2C94C;
}

a {
  -webkit-text-decoration-color: #F2C94C;
          text-decoration-color: #F2C94C;
  margin-right: 10px;
  display: inline;
  font-size: 15pt;
}

a:visited {
  color: #333333;
}

.darkmode a:visited {
  color: #f2f2f2;
}

.darkmode a:link {
  color: #f2f2f2;
}


a:link {
  color: #333333;
}

a:hover {
  color: #777777;
}


.App {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  background-color: #F2F2F2;
  font-size: 30px;
  min-width: 300px;
  min-height: 100vh;
}

.App.darkmode{
  color: #f2f2f2;
  background-color: #333333;
}

