.App {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  background-color: #F2F2F2;
  font-size: 30px;
  min-width: 300px;
  min-height: 100vh;
}

.App.darkmode{
  color: #f2f2f2;
  background-color: #333333;
}
