.Card {
  max-width: 600px;
  padding: 40px;
  border-radius: 20px;
  margin: auto;
}

.Line {
  background-color: #F2C94C;
  height: 10px;
  margin-top: 27px;
  margin-bottom: 27px;
  border-radius: 10px;
}

.Email {
  margin-top: 100px;

}
@media only screen and (max-width: 650px) {
  .Email {
    font-size: 15pt;
  }
}

u {
  text-decoration-color: #F2C94C;
}

a {
  text-decoration-color: #F2C94C;
  margin-right: 10px;
  display: inline;
  font-size: 15pt;
}

a:visited {
  color: #333333;
}

.darkmode a:visited {
  color: #f2f2f2;
}

.darkmode a:link {
  color: #f2f2f2;
}


a:link {
  color: #333333;
}

a:hover {
  color: #777777;
}

