body {
  margin: 0;
  padding: 0;
  font-size: 30px;
}

h1 {
  font-family: 'Montserrat',
    sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 120px;
  line-height: 80%;
  margin: 0;
}

@media only screen and (max-width: 650px) {
  body {
    font-size: 17px;
  }

  h1 {
    font-size: 40px;
  }

}

p {
  margin: 0;
}